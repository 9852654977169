import get from 'lodash/get';
import omit from 'lodash/omit';
import { ComponentType, ReactElement } from 'react';
import styled, { CSSObject, FlattenSimpleInterpolation, Interpolation, SimpleInterpolation, ThemeProvider, ThemedStyledFunction, css } from 'styled-components';
import theme, { Color, TextStyle, Theme, ThemeProps } from '.';
import muiTheme from '@legacy/theme/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
type ResponsiveSize = 'phoneS' | 'phoneM' | 'phoneL' | 'tabletS' | 'tabletL' | 'laptop' | 'laptopL' | 'desktop';
export type SizeByDevice = { [deviceSizeName in ResponsiveSize]: number };
type DeviceSelector<P extends ThemeProps> = (first: TemplateStringsArray | CSSObject, ...interpolations: Interpolation<P>[]) => FlattenSimpleInterpolation;
type DeviceMedia<P extends ThemeProps> = { [deviceSizeName in ResponsiveSize]: DeviceSelector<P> };
export const sizes: SizeByDevice = {
  phoneS: 360,
  phoneM: 400,
  phoneL: 600,
  tabletS: 720,
  tabletL: 840,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560
};
export const DEFAULT_BODY_FONT_SIZE = 16;
const deviceSizes = ['phoneS', 'phoneM', 'phoneL', 'tabletS', 'tabletL', 'laptop', 'laptopL', 'desktop'];
const DEFAULT_EXCLUDED_PROPS: string[] = ['theme', 'innerReference', 'borderLess', 'withLargeText', 'maxFontSizeMultiplier'];
export const deviceMedia: DeviceMedia<ThemeProps> = deviceSizes.reduce((acc: DeviceMedia<ThemeProps>, deviceSizeName: ResponsiveSize) => {
  acc[deviceSizeName] = (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]): FlattenSimpleInterpolation => css`
      @media (max-width: ${sizes[deviceSizeName] / DEFAULT_BODY_FONT_SIZE}em) {
        ${css(first, ...interpolations)};
      }
    `;
  return acc;
}, {} as DeviceMedia<ThemeProps>);
export function withTheme(component: ReactElement): ReactElement {
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="withTheme" data-sentry-source-file="helper.tsx">
      <MuiThemeProvider theme={muiTheme} data-sentry-element="MuiThemeProvider" data-sentry-source-file="helper.tsx">{component}</MuiThemeProvider>
    </ThemeProvider>;
}

/**
 * Unfortunately styled-components is not always good at filtering props down.
 * To avoid warnings and errors especially on browsers this helper function will
 * help filtering non expected DOM props.
 * Use special props `innerReference` to pass down the reference to the special ref prop.
 *
 * @see https://github.com/styled-components/styled-components/issues/135
 * @param Component
 * @param excludedProps
 */
export function styledWithoutProps<P extends object>(Component: ComponentType<Partial<P>>, ...excludedProps: string[]): ThemedStyledFunction<ComponentType<P>, Theme> {
  const CleanedComponent = (props: P): ReactElement => {
    const cleanedProps: Partial<P> = omit(props, [...DEFAULT_EXCLUDED_PROPS, ...excludedProps]);
    return <Component ref={get(props, 'innerReference', null)} {...cleanedProps} data-sentry-element="Component" data-sentry-component="CleanedComponent" data-sentry-source-file="helper.tsx" />;
  };
  CleanedComponent.displayName = Component.displayName;
  return styled(CleanedComponent);
}
type SupportedPlatformOSType = 'android' | 'ios' | 'web';
type PlatformSelector = SupportedPlatformOSType | 'mobile';
const supportedPlatformSpecifics: SupportedPlatformOSType[] = ['android', 'ios', 'web'];
type StyledSelector<P extends ThemeProps> = (first: TemplateStringsArray | CSSObject, ...interpolations: Interpolation<P>[]) => FlattenSimpleInterpolation;
type PlatformSpecific<P extends ThemeProps> = { [platformSelector in PlatformSelector]: StyledSelector<P> };
function makeSelector<P extends ThemeProps>(condition: boolean): StyledSelector<P> {
  return (first: TemplateStringsArray | CSSObject, ...interpolations: Interpolation<P>[]): FlattenSimpleInterpolation => {
    // @ts-ignore
    return condition ? css(first, ...interpolations) : [];
  };
}
export const platformSpecific: PlatformSpecific<ThemeProps> = {
  ...supportedPlatformSpecifics.reduce((acc: PlatformSpecific<ThemeProps>, platformType: SupportedPlatformOSType) => {
    acc[platformType] = makeSelector(true);
    return acc;
  }, {} as PlatformSpecific<ThemeProps>),
  mobile: makeSelector(false)
};
export type ThemeSelector = ({
  theme
}: ThemeProps) => string;
export type SizeableProperty = 'radius' | 'spacing' | 'border';
export enum Size {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export function colorFromTheme(color: Color): ThemeSelector {
  return (props: ThemeProps): string => props.theme.color[color];
}
function sizeableFromTheme(size: Size, sizeableProperty: SizeableProperty): ThemeSelector {
  return (props: ThemeProps): string => props.theme[sizeableProperty][size];
}
export function radiusFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'radius');
}
export function spacingFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'spacing');
}
export function borderFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'border');
}
export type AdaptiveTextStyleOptions = {
  iosStyleKey?: TextStyle;
  androidStyleKey?: TextStyle;
  mobileStyleKey?: TextStyle;
  webStyleKey?: TextStyle;
};
export function adaptiveTextStyle(styleKey: TextStyle, options?: AdaptiveTextStyleOptions): TextStyle {
  if (options?.webStyleKey) return options.webStyleKey;
  return styleKey;
}