import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Color, ThemeProps, em } from '../theme';
import { Size, platformSpecific, radiusFromTheme } from '../theme/helper';

export interface WrapperProps extends ThemeProps {
  wrapperColor: Color;
}

export const Wrapper = styled.div<WrapperProps>`
  height: ${em(2)};
  width: ${em(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${em(0.25)};
  ${({ wrapperColor, theme }: WrapperProps): FlattenSimpleInterpolation => css`
    background-color: ${theme.color[wrapperColor]};
  `}

  ${platformSpecific.ios`
    border-radius: ${radiusFromTheme(Size.LARGE)};
  `}

  ${platformSpecific.android`
    border-radius: ${radiusFromTheme(Size.MEDIUM)};
  `}
`;
