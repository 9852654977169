import { ReactElement } from 'react';
import { G, Svg, Path, Line } from 'svgs';
import { ThemedSymbolProps } from './typings';
import { Color } from '../../theme';
const PaymentMealVoucherCard = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => <Svg {...svgProps} data-sentry-element="Svg" data-sentry-component="PaymentMealVoucherCard" data-sentry-source-file="PaymentMealVoucherCard.tsx">
    <G fill="none" data-sentry-element="G" data-sentry-source-file="PaymentMealVoucherCard.tsx">
      <Path d="M8 1H32C35.866 1 39 4.13401 39 8V20C39 23.866 35.866 27 32 27H8C4.13401 27 1 23.866 1 20V8C1 4.13401 4.13401 1 8 1Z" strokeWidth={2} stroke={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]} data-sentry-element="Path" data-sentry-source-file="PaymentMealVoucherCard.tsx" />
      <Line y1={8} x2={38.6813} y2={8} strokeWidth={2} stroke={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]} data-sentry-element="Line" data-sentry-source-file="PaymentMealVoucherCard.tsx" />
      <Line x1={5.27466} y1={15} x2={16.7032} y2={15} strokeWidth={2} stroke={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]} data-sentry-element="Line" data-sentry-source-file="PaymentMealVoucherCard.tsx" />
    </G>
  </Svg>;
export default PaymentMealVoucherCard;