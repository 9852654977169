import ICloudinaryConfigurations from '@cloudinary/url-gen/config/interfaces/Config/ICloudinaryConfigurations';
import { CookieAttributes } from 'js-cookie';

export type EnvironmentString =
  | 'test'
  | 'development'
  | 'staging'
  | 'production';

export interface Config {
  environment: EnvironmentString;
  isBrowser: boolean;
  apiEndpoint: string;
  cdnEndpoint: string;
  graphQLEndpoint: string;
  defaultLanguage: string;
  acceptedLanguages: string[];
  sentry: {
    environment: string;
    dsn: string;
  };
  currency: {
    default: {
      code: 'EUR';
    };
  };
  auth: {
    cookieOptions: Partial<CookieAttributes>;
  };
  food: {
    temperature: {
      hotSlug: string;
      coldSlug: string;
    };
  };
  googleAnalyticsTrackingID: string;
  foodlesLandingPageDomain: string;
  mobileAppListings: {
    googlePlay: Record<string, string>;
    appStore: Record<string, string>;
  };
  cloudinary: ICloudinaryConfigurations;
  smartlookProjectKey: string;
}

export const APP_NAME = 'customer-web-app';
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const environment: EnvironmentString = (process.env.NODE_ENV ??
  'development') as EnvironmentString;
const apiEndpoint =
  process.env.NEXT_PUBLIC_API_ENDPOINT ?? 'http://localhost:8000/api';
const cdnEndpoint =
  process.env.NEXT_PUBLIC_CDN_ENDPOINT ?? 'https://cdn.foodles.co';
const graphQLEndpoint =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ?? 'http://localhost:3000/graphql';

const config: Config = {
  environment,
  apiEndpoint,
  cdnEndpoint,
  graphQLEndpoint,
  sentry: {
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ?? 'Dev',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? '',
  },
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  isBrowser: process.browser,
  defaultLanguage: process.env.DEFAULT_LANGUAGE ?? 'fr',
  acceptedLanguages: (process.env.ACCEPTED_LANGUAGES ?? 'fr,en').split(','),
  auth: {
    cookieOptions: {
      domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? 'localhost',
    },
  },
  currency: {
    default: {
      code: 'EUR',
    },
  },
  food: {
    temperature: {
      hotSlug: process.env.NEXT_PUBLIC_FOOD_HOT_TAG ?? 'chaud',
      coldSlug: process.env.NEXT_PUBLIC_FOOD_COLD_TAG ?? 'froid',
    },
  },
  googleAnalyticsTrackingID: process.env.GOOGLE_ANALYTICS_TRACKING_ID ?? '',
  foodlesLandingPageDomain:
    process.env.LANDING_PAGE ?? 'https://www.foodles.co',
  mobileAppListings: {
    googlePlay: {
      en: 'https://play.google.com/store/apps/details?id=co.foodles.customerapp.v2&hl=en',
      fr: 'https://play.google.com/store/apps/details?id=co.foodles.customerapp.v2&hl=fr',
    },
    appStore: {
      en: 'https://apps.apple.com/us/app/foodles/id1460002316?ls=1',
      fr: 'https://apps.apple.com/fr/app/foodles/id1460002316?ls=1',
    },
  },
  cloudinary: {
    cloud: {
      cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME ?? 'foodles',
    },
  },
  smartlookProjectKey: process.env.SMARTLOOK_PROJECT_KEY ?? '',
};

export default config;
