import { ReactElement } from 'react';
import { G, Path, Rect, Svg } from 'svgs';
import { SymbolProps } from './typings';
const PaymentCardDinersClub = ({
  ...svgProps
}: SymbolProps): ReactElement => <Svg {...svgProps} data-sentry-element="Svg" data-sentry-component="PaymentCardDinersClub" data-sentry-source-file="PaymentCardDinersClub.tsx">
    <G id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" data-sentry-element="G" data-sentry-source-file="PaymentCardDinersClub.tsx">
      <G id="diners" fillRule="nonzero" data-sentry-element="G" data-sentry-source-file="PaymentCardDinersClub.tsx">
        <Rect id="rectangle" fill="#0079BE" x="0" y="0" width="750" height="471" rx="40" data-sentry-element="Rect" data-sentry-source-file="PaymentCardDinersClub.tsx" />
        <Path d="M584.933911,237.947339 C584.933911,138.53154 501.952976,69.8140806 411.038924,69.8471464 L332.79674,69.8471464 C240.793699,69.8140806 165.066089,138.552041 165.066089,237.947339 C165.066089,328.877778 240.793699,403.587432 332.79674,403.150963 L411.038924,403.150963 C501.952976,403.586771 584.933911,328.857939 584.933911,237.947339 Z" id="Shape-Path" fill="#FFFFFF" data-sentry-element="Path" data-sentry-source-file="PaymentCardDinersClub.tsx" />
        <Path d="M333.280302,83.9308394 C249.210378,83.9572921 181.085889,152.238282 181.066089,236.510581 C181.085889,320.768331 249.209719,389.042708 333.280302,389.069161 C417.370025,389.042708 485.508375,320.768331 485.520254,236.510581 C485.507715,152.238282 417.370025,83.9572921 333.280302,83.9308394 Z" id="Shape-Path" fill="#0079BE" data-sentry-element="Path" data-sentry-source-file="PaymentCardDinersClub.tsx" />
        <Path d="M237.066089,236.09774 C237.145288,194.917524 262.812421,159.801587 299.006443,145.847134 L299.006443,326.327183 C262.812421,312.380667 237.144628,277.283907 237.066089,236.09774 Z M368.066089,326.372814 L368.066089,145.847134 C404.273312,159.767859 429.980043,194.903637 430.046043,236.103692 C429.980043,277.316312 404.273312,312.425636 368.066089,326.372814 Z" id="Path" fill="#FFFFFF" data-sentry-element="Path" data-sentry-source-file="PaymentCardDinersClub.tsx" />
      </G>
    </G>
  </Svg>;
export default PaymentCardDinersClub;