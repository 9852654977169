
import { Color } from '../colors';
import { StyleDictionary, ThemeProps } from '../typings';

export const maxFontSizeMultiplier = 1.2;
// Setting default window with for handling SSR support
const windowWidth = 800;
const BASE_SCALE = windowWidth > 340 ? 1 : 0.75;
const FONT_SCALE = Math.min(1, maxFontSizeMultiplier);

export type ScaleOptions = {
  forFont?: boolean;
};

export function em(
  unit: number,
  { forFont = false }: ScaleOptions = {},
): string {
  return `${unit}em`;

}

export function rem(
  unit: number,
  { forFont = false }: ScaleOptions = {},
): string {
  return `${unit}rem`;
}

export function scaleUnitLess(
  unit: number,
  { forFont = false }: ScaleOptions = {},
): number {
  return unit * BASE_SCALE * (forFont ? 1 : FONT_SCALE);
}

const commonTextStyle: StyleDictionary = {
  fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif, "Segoe UI", Roboto, Ubuntu',
  color: ({ theme }: ThemeProps): string => theme.color[Color.DARK],
};

export enum TextStyle {
  TINY_BUTTON_LABEL = 'tinyButtonLabel',
  BUTTON_LABEL = 'buttonLabel',
  HEADER_1 = 'header1',
  HEADER_2 = 'header2',
  HEADER_3 = 'header3',
  HEADER_4 = 'header4',
  HEADER_5 = 'header5',
  HEADER_6 = 'header6',
  BODY_1 = 'body1',
  BODY_2 = 'body2',
  BODY_3 = 'body3',
  BODY_4 = 'body4',
  BODY_5 = 'body5',
}

// Define exhaustively first, refactor when there is enough text styles
export const textStyles: { [styleKey: string]: StyleDictionary } = {
  [TextStyle.TINY_BUTTON_LABEL]: {
    ...commonTextStyle,
  },
  [TextStyle.BUTTON_LABEL]: {
    ...commonTextStyle,
  },
  [TextStyle.HEADER_1]: {
    ...commonTextStyle,
  },
  [TextStyle.HEADER_2]: {
    ...commonTextStyle,
  },
  [TextStyle.HEADER_3]: {
    ...commonTextStyle,
    fontSize: em(1.5, { forFont: true }),
    fontWeight: '700',
  },
  [TextStyle.HEADER_4]: {
    ...commonTextStyle,
    fontSize: em(1.25, { forFont: true }),
    fontWeight: '600',
  },
  [TextStyle.HEADER_5]: {
    ...commonTextStyle,
    fontSize: em(1, { forFont: true }),
    fontWeight: '500',
  },
  [TextStyle.HEADER_6]: {
    ...commonTextStyle,
    fontSize: em(0.875, { forFont: true }),
    fontWeight: '700',
  },
  [TextStyle.BODY_1]: {
    ...commonTextStyle,
  },
  [TextStyle.BODY_2]: {
    ...commonTextStyle,
  },
  [TextStyle.BODY_3]: {
    ...commonTextStyle,
  },
  [TextStyle.BODY_4]: {
    ...commonTextStyle,
    fontSize: em(0.5, { forFont: true }),
    fontWeight: '600',
  },
  [TextStyle.BODY_5]: {
    ...commonTextStyle,
    fontSize: em(1, { forFont: true }),
    fontWeight: '900',
  },
};
