import colors, { Color } from '@legacy-components/theme/colors';
import legacyPalette from '@legacy-components/theme/colors/palette';

const palette = {
  common: {
    white: '#fff',
    black: '#000',
  },
  secondary: {
    main: colors[Color.SECONDARY],
  },
  blue: {
    main: colors[Color.PRIMARY],
  },
  danger: {
    main: colors[Color.DANGER],
  },
  light: {
    main: colors[Color.LIGHT],
  },
  poultry: {
    main: colors[Color.POULTRY],
  },
  grey: {
    100: legacyPalette.gray.gray100,
    200: legacyPalette.gray.gray200,
    300: legacyPalette.gray.gray300,
    400: legacyPalette.gray.gray400,
    500: legacyPalette.gray.gray500,
  },
};

export default palette;
