import { ReactElement } from 'react';
import { G, Svg } from 'svgs';
import { ThemedSymbolProps } from './typings';
const Placeholder = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => <Svg {...svgProps} data-sentry-element="Svg" data-sentry-component="Placeholder" data-sentry-source-file="Placeholder.tsx">
    <G data-sentry-element="G" data-sentry-source-file="Placeholder.tsx" />
  </Svg>;
export default Placeholder;