import color, { palette } from './colors';
import { em, maxFontSizeMultiplier } from './text/common';
import textStyle from './text/styles';
import { Theme } from './typings';

export * from './colors';
export * from './text/styles';
export * from './typings';

const theme: Theme = {
  textStyle,
  color,
  palette,
  maxFontSizeMultiplier,
  radius: {
    small: em(0.25),
    medium: em(0.5),
    large: em(0.75),
  },
  spacing: {
    small: em(0.625),
    medium: em(1),
    large: em(1.875),
  },
  border: {
    small: em(0.0625),
    medium: em(0.125),
    large: em(0.25),
  },
  shadow: {
    small: em(0.25),
    medium: em(0.5),
    large: em(1),
  },
  dark: false,
  roundness: 4
};

export default theme;
